import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <>
    <SEO title={"Work | Gportal - Game Server Hosting"} slug={"gportal"} />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>
              Gportal - Transforming Game Server Hosting with a Fresh Web
              Interface
            </h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="case-study-content">
      <Container
        className="hero-container"
        style={{ background: "#dedede", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/gportal/gportal_2.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>

      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="6" className="p-0 section-text">
              <h3>01 Challenge</h3>
              <p className="gray">
                Gportal specializes in providing game server hosting within the
                Gamecloud. The client sought my expertise to create a completely
                fresh web platform interface, leveraging pre-designed layouts as
                the foundation.
                <br />
                <br />I collaborated closely with the design team, product
                owners, and backend developers to bring their newly designed
                layouts to life. In this productive partnership, I spearheaded
                the development of the new front-end layer. Along the way, I
                also introduced several user experience (UX) enhancements that
                played a pivotal role in elevating the platform's international
                success.
              </p>
            </Col>
            <Col sm={{ size: 4, offset: 2 }} className="p-0 section-text">
              <h4>Scope</h4>
              <p className="gray">Front-end development / UI / UX</p>
              <h4>Date</h4>
              <p className="gray">2014 - 2016</p>
              <h4>Industry & location</h4>
              <p className="gray">Gaming / Munich, Germany</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "#fff", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/gportal/gportal_3.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="gportal" bgcolor="#f3f3f3" />
  </>
)

SecondPage.Layout = Layout
export default SecondPage
